import React from "react"
import { useSiteMetadata } from "../../hooks/use-site-metadata"
import { SocialButton } from "./social-button"

const LINKS = {
  facebook: "https://facebook.com/sharer/sharer.php?u=#{URL}",
  twitter: "https://twitter.com/intent/tweet?url=#{URL}&text=#{TEXT}",
  linkedin:
    "http://linkedin.com/shareArticle?mini=true&url=#{URL}&title=#{TEXT}",
  reddit: "https://reddit.com/submit?url=#{URL}&title=#{TEXT}",
}

function getLink(socialNetworkName, params) {
  let link = LINKS[socialNetworkName]

  if (link) {
    for (const [key, value] of Object.entries(params)) {
      link = link.replace(`#{${key.toUpperCase()}}`, value)
    }

    return link
  }

  console.warn(`SocialSharingButton: ${socialNetworkName} is not supported.`)
  return null
}

export const SocialSharingButton = ({ className, name, slug, textOrTitle }) => {
  const { pageUrl } = useSiteMetadata(slug)

  const link = getLink(name, {
    url: pageUrl,
    text: textOrTitle,
  })

  if (link) {
    return <SocialButton className={className} link={link} />
  }

  return null
}
