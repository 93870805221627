import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

const LanguagesList = styled.ul`
  display: flex;
  padding-left: 0.5em;
  margin-block-start: 0;
  margin-block-end: 0;

  li {
    list-style-type: none;
    &:not(:last-child) {
      padding-right: 0.5em;
      &::after {
        content: ",";
      }
    }
  }
`

const Container = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
  font-size: 90%;
`

const messages = {
  en: "Also available in",
  pl: "Dostępne wersje językowe:",
  ja: "言語設定",
}

const LangDisplayNames = {
  en: {
    en: "English",
    pl: "Polish",
    ja: "Japanese",
  },
  pl: {
    en: "angielski",
    pl: "polski",
    ja: "japoński",
  },
  ja: {
    en: "英語",
    pl: "ポーランド語",
    ja: "日本語",
  },
}

export const Languages = ({ lang = "en", values = [] }) => {
  if (!values.length) {
    return null
  }

  const message = messages[lang] || messages["en"]
  const languageNames = LangDisplayNames[lang]

  if (!languageNames) {
    throw new Error(`Languages: ${lang} not supported`)
  }

  return (
    <Container>
      <strong>{message}</strong>
      <LanguagesList>
        {values.map(({ slug, lang }) => (
          <li key={lang}>
            <Link to={`/${slug}`}>{languageNames[lang]}</Link>
          </li>
        ))}
      </LanguagesList>
    </Container>
  )
}
