import styled from "@emotion/styled"
import React from "react"
import { SocialSharingButton } from "./social-sharing-button"

const List = styled.ul`
  margin: 0.5rem 0;
  padding: 0;
  display: flex;
  list-style-type: none;

  li {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
`

export const SocialShares = ({
  className,
  slug,
  textOrTitle,
  platforms = [],
}) => {
  return (
    <List className={className}>
      {platforms.map(name => (
        <li key={name}>
          <SocialSharingButton
            name={name}
            slug={slug}
            textOrTitle={textOrTitle}
          />
        </li>
      ))}
    </List>
  )
}
