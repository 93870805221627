import styled from "@emotion/styled"
import GatsbyImage from "gatsby-image"
import React from "react"
import { theme } from "../theme"
import BadgeSVG from "../../content/assets/badge.inline.svg"

const LABELS = {
  en: {
    name: "Name",
    zodiac: "Zodiac sign or spirit animal",
    age: "Age",
    sport: "Sport",
    achievement: "Proudest achievement in strength sports",
    sportAdvice: "#1 tip for getting better in strength sports",
    lifeAdvice: "#1 tip for getting better in life",
  },
  ja: {
    name: "名前",
    zodiac: "星座/あなたを動物に例えると",
    age: "年齢",
    sport: "スポーツ",
    achievement: "ストレングススポーツでこれまでに達成した最も良い成績",
    sportAdvice: "ストレングススポーツをより良くする一番の秘訣",
    lifeAdvice: "人生をより良いものにする一番の秘訣",
  },
}

const BADGE_SIZE = "350px"

const Profile = styled.aside`
  display: flex;
  justify-content: space-between;
  align-items: center;

  dl {
    display: grid;
    margin: 1rem;
  }

  dt,
  dd {
    margin: 0.15rem 1rem;
  }

  dt {
    grid-column: 1;
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.highlightDark};
    text-align: right;
    margin-left: 0;
  }

  dd {
    grid-column: 2;
    max-width: 25rem;
  }

  @media (max-width: 850px) {
    flex-direction: column;
  }
`

const FixedWrapper = styled.div`
  position: relative;
  min-width: ${BADGE_SIZE};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 850px) {
    min-height: ${BADGE_SIZE};
    min-width: 100%;
    margin-bottom: -1rem;
    margin-top: 0.5rem;
  }
`

const ProfilePicture = styled.div`
  position: absolute;
  width: ${BADGE_SIZE};

  @media (max-width: ${BADGE_SIZE}) {
    width: 100%;
  }
`

const Badge = styled(BadgeSVG)`
  width: 100%;
  height: auto;
  position: absolute;
`

const Photo = styled(GatsbyImage)`
  margin: 8%;

  img {
    border-radius: 100%;
    border: 6px solid #fff;
    box-sizing: border-box;
  }
`

function getProfileToDisplay(labels, data) {
  return Object.entries(labels).map(([key, label]) => ({
    label,
    value: data[key],
  }))
}

export const IntervieweeProfile = ({ profile, photo, lang = "en" }) => {
  const list = getProfileToDisplay(LABELS[lang], profile)

  return (
    <Profile>
      <dl>
        {list.map(({ label, value }) => (
          <React.Fragment key={label}>
            <dt>{label}</dt>
            <dd>{value}</dd>
          </React.Fragment>
        ))}
      </dl>
      <FixedWrapper>
        <ProfilePicture>
          <Badge />
          <Photo fluid={photo} alt={profile.name} />
        </ProfilePicture>
      </FixedWrapper>
    </Profile>
  )
}
