import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import { InterviewFooter } from "../components/interview-footer"
import { IntervieweeProfile } from "../components/interviewee-profile"
import { Languages } from "../components/languages"
import { Footer as LayoutFooter, Layout, Page, Row } from "../components/layout"
import { SEO } from "../components/seo"
import { PAGE_TYPES } from "../components/seo/schemas"
import { SocialShares } from "../components/social/social-shares"
import { highlightBackgroud } from "../styles/global"
import { theme } from "../theme"

const Header = styled(Row.withComponent("header"))`
  h1 {
    color: ${theme.colors.secondary};
    margin-bottom: 4rem;
    text-transform: none;
  }
`

const Footer = styled(LayoutFooter)`
  margin-top: 6rem;
`

const Section = styled(Row.withComponent("section"))`
  margin-top: 6rem;

  @media (max-width: 850px) {
    margin-top: 10rem;
  }
`

const WideRow = styled(Row)`
  padding: 0;
  max-width: 94rem;
`

const Hero = styled.section`
  flex: 1;
  ${highlightBackgroud}
`

const ArticleFooter = styled(Section.withComponent("footer"))``

const InterviewTemplate = ({
  data: { mdx: post },
  pageContext: { variants = [] },
}) => {
  const { slug, lang } = post.fields
  const { title, profile } = post.frontmatter
  const photo = profile.photo.image.data
  const otherLangVariants = variants.filter(v => v.lang !== lang)

  return (
    <Page>
      <Layout
        footer={() => (
          <Footer>
            <Hero>
              <Row>
                <InterviewFooter quote="The best things in life are on the other side of the fear" />
              </Row>
            </Hero>
          </Footer>
        )}
      >
        <SEO
          lang={lang}
          availableLanguages={variants}
          slug={slug}
          title={title}
          description={post.frontmatter.description || post.excerpt}
          schemaOrgType={PAGE_TYPES.BLOG}
        />
        <article>
          <Header>
            <h1>{title}</h1>
            <Languages lang={lang} values={otherLangVariants} />
          </Header>
          <Hero>
            <WideRow>
              <IntervieweeProfile lang={lang} profile={profile} photo={photo} />
            </WideRow>
          </Hero>
          <Section>
            <MDXRenderer slug={slug}>{post.body}</MDXRenderer>
          </Section>
          <ArticleFooter>
            <em>If you liked it, please share</em>
            <span role="img" aria-label="heart emoji">
              {" "}
              ❤️
            </span>
            <SocialShares
              slug={slug}
              textOrTitle={title}
              platforms={["twitter", "linkedin", "facebook"]}
            />
          </ArticleFooter>
        </article>
      </Layout>
    </Page>
  )
}

export default InterviewTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        profile {
          name
          zodiac
          age
          sport
          achievement
          sportAdvice
          lifeAdvice
          photo {
            image: childImageSharp {
              data: fluid(
                quality: 90
                maxWidth: 300
                srcSetBreakpoints: [300]
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      fields {
        draft
        slug
        lang
      }
    }
  }
`
