import styled from "@emotion/styled"
import React from "react"
import { theme } from "../theme"
import { Bio } from "./bio"
import { Copyright } from "./layout"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 850px) {
    flex-direction: row;
  }
`

const StyledBio = styled(Bio)`
  margin: 1rem 0;
`

const StyledCopyright = styled(Copyright)`
  margin-top: 2rem;
  font-size: ${theme.fontSizes[1]};
  color: ${theme.colors.text};
  align-self: center;
`

export const InterviewFooter = ({ quote }) => {
  return (
    <Container>
      <Flex>
        <StyledBio />
        <blockquote>{quote}</blockquote>
      </Flex>
      <StyledCopyright />
    </Container>
  )
}
